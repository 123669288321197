import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function useUsersList() {
  // Use toast
  const toast = useToast()

  const refTrainingCategoryListTable = ref(null)

  // Table Handlers
  const tableColumns = [
    { key: 'name', label: 'Eğitim Adı', sortable: true },
    { key: 'description', label: 'Eğitim Açıklaması', sortable: true },
    { key: 'start_date', label: 'Başlangıç Tarihi' },
    { key: 'end_date', label: 'Bitiş Tarihi' },
    { key: 'actions', label: '' },
  ]
  const perPage = ref(10)
  const totalTrainingCategories = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const searchQuery = ref('')
  const sortBy = ref('id')
  const isSortDirDesc = ref(true)
  const roleFilter = ref(null)
  const planFilter = ref(null)
  const statusFilter = ref(null)

  const dataMeta = computed(() => {
    const localItemsCount = refTrainingCategoryListTable.value ? refTrainingCategoryListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalTrainingCategories.value,
    }
  })

  const refetchData = () => {
    refTrainingCategoryListTable.value.refresh()
  }

  watch([currentPage, perPage, searchQuery, roleFilter, planFilter, statusFilter], () => {
    refetchData()
  })

  const fetchTrainingCategories = (ctx, callback) => {
    store
      .dispatch('app-training/fetchTrainings', {
        q: searchQuery.value,
        perPage: perPage.value,
        page: currentPage.value,
        sortBy: sortBy.value,
        sortDesc: isSortDirDesc.value,
        role: roleFilter.value,
        plan: planFilter.value,
        status: statusFilter.value,
      })
      .then(response => {
        const { data } = response.data
        const { total } = response.data.meta

        callback(data)
        totalTrainingCategories.value = total
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching training list',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*
  return {
    fetchTrainingCategories,
    tableColumns,
    perPage,
    currentPage,
    totalTrainingCategories,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refTrainingCategoryListTable,

    refetchData,

    // Extra Filters
    roleFilter,
    planFilter,
    statusFilter,
  }
}
